import HeaderNavigation from "@/components/common/Layout/header/HeaderNavigation"
import Buy from "@/components/homepage/Buy"
import ListingMobile from "@/components/homepage/ListingsMobile/ListingMobile"
import BuyMobile from "@/components/homepage/BuyMobile/BuyMobile"
import Faq from "@/components/homepage/Faq/Faq"
import Highlights from "@/components/homepage/Listings"
import Homebanner from "@/components/homepage/banner/Homebanner"
import HomebannerTest from "@/components/homepage/banner/HomebannerTest"
import Video from "@/components/homepage/video/Video"
import Performance from "@/components/homepage/performance/performance"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import CTA from "@/components/homepage/CTA/CTA"
import DealSlider from "@/components/homepage/ListingsMobile/Dealslider"
import { useContext, useEffect } from "react"
import { Context } from "@/context/state"

const Home = ({ session }) => {
  const isFeatureOn = useFeatureIsOn("homepage-test")

  const { state, dispatch } = useContext(Context)

  useEffect(() => {
    if (!state?.listings) {
      const fetchData = async () => {
        dispatch({ type: "SET_LOADING", payload: true })

        try {
          const response = await fetch("/api/listings?split=true&limit=4")
          if (!response.ok) {
            throw new Error("Network response was not ok")
          }
          const result = await response.json()
          const projects = result.sort(
            (a, b) =>
              new Date(b?.splitInvestment?.expirationDate) -
              new Date(a?.splitInvestment?.expirationDate)
          )

          dispatch({
            type: "SET_LISTINGS",
            payload: projects.length ? projects : [],
          })
        } catch (err) {
          console.error(err)
          dispatch({ type: "SET_LISTINGS", payload: [] })
        } finally {
          dispatch({ type: "SET_LOADING", payload: false })
        }
      }

      fetchData()
    }
  }, [state.listings, dispatch])

  return (
    <>
      <HeaderNavigation />
      {!isFeatureOn ? <Homebanner /> : <HomebannerTest />}
      <Performance />
      {/* <PerformanceSlider totalInvestment={totalInvestment} /> */}
      <div className="d-none d-lg-block">
        <Highlights session={session} />
      </div>
      <div className="d-block d-lg-none">
        <DealSlider session={session} />
      </div>
      <Video />
      <div className="d-none d-md-block">
        <Buy />
      </div>
      <div className="d-md-none">
        <BuyMobile />
      </div>
      {/* <Review /> */}
      <Faq />
      <CTA />
    </>
  )
}

export default Home
